import { Cache, NullArray } from '@urql/exchange-graphcache';

const collator = new Intl.Collator('en', {
  numeric: true,
  sensitivity: 'base',
});

export const predefinedLocationKindsOrder: Record<string, number> = {
  LocationGroup: 1,
  Building: 2,
  Unit: 3,
};

export const predefinedDeviceKindsOrder: Record<string, number> = {
  AlexaDevice: 1,
  DigitalSign: 2,
};

/**
 * Sorts an array of edges by their kind and name.
 * Sorting is required as `onePageCacheResolver` merges multiple pages into a single list,
 * and the order must remain consistent.
 *
 * @param {Cache} cache - The cache instance used for resolving entities and their properties.
 * @param {NullArray<string>} edges - The array of edges to be sorted, potentially including null values.
 * @param {string} sortableEntity - The entity type to sort by `kind` and `name`.
 * @param {Record<string, number>} [predefinedKindOrder] - A predefined order for sorting by `kind`.
 * @returns {NullArray<string>} The sorted array of edges
 *
 * @example
 * Note:Edges represent not actual data return from the backend but the key of the data stored in the Urql cache.
 * const edges = [' OrganizationLocationEdge:03f8b066-c48d-4c1c-9abe-4f323f31f800-loc_mpNFArSBzg1G1h54XLQE6D'];
 */
export const sortByKindAndName = (
  cache: Cache,
  edges: NullArray<string>,
  sortableEntity: string,
  predefinedKindOrder: Record<string, number>,
) => {
  const processedEdges = [];
  for (const edge of edges) {
    const edgeKey = ensureKey(edge);
    if (edgeKey) {
      const entity = cache.resolve(edgeKey, sortableEntity);
      //E.g. sortableEntity = "location" => entity = "Building:loc_nXewkCh3p4fQoWen72GYP4"

      const entityKey = ensureKey(entity);
      const kind = cache.resolve(entityKey, 'kind') as string;
      const name = cache.resolve(entityKey, 'name') as string;
      processedEdges.push([edge, kind, name] as const);
    }
  }

  processedEdges.sort(([_, kindA, nameA], [__, kindB, nameB]) => {
    // Compare by predefined order if both kinds exist in the predefined order
    const orderA = predefinedKindOrder[kindA];
    const orderB = predefinedKindOrder[kindB];

    if (orderA !== undefined && orderB !== undefined && orderA !== orderB) {
      return orderA - orderB;
    }

    const safeNameA = nameA.toLocaleLowerCase();
    const safeNameB = nameB.toLocaleLowerCase();
    return collator.compare(safeNameA, safeNameB);
  });

  return processedEdges.map(([originalItem]) => originalItem);
};

// Helper function to ensure a cache key is a string
export const ensureKey = (x: any): string => (typeof x === 'string' ? x : '');
