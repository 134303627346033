import { PageInfo } from '@serenityapp/core';
import { NullArray, Resolver, Cache } from '@urql/exchange-graphcache';
import {
  ensureKey,
  predefinedDeviceKindsOrder,
  predefinedLocationKindsOrder,
  sortByKindAndName,
} from './utils';

// Define the structure for a page of results
interface Page {
  __typename: string;
  edges: NullArray<string>;
  pageInfo: PageInfo;
}

// Default PageInfo object to use when none is provided
const defaultPageInfo: PageInfo = {
  endCursor: '',
  startCursor: '',
  hasNextPage: false,
  hasPreviousPage: false,
};

/**
 * This is a custom cache resolver that takes all target field pages (queries with different
 * variables) from cache and merges them into a single page.
 *
 * This resolver's logic was based on the relay pagination example:
 * @see {@link https://urql.dev/goto/docs/graphcache/local-resolvers#relay-pagination} for more information.
 */
const onePageCacheResolver: Resolver = (parent, args, cache, info) => {
  // e.g. parentKey is `organization`
  // e.g. fieldName is `location`, `device`, etc...
  const { parentKey: entityKey, fieldName } = info;

  // Inspect all fields on the parent entity to find target fields (if this function is called
  // for `locations` here we are inspecting all fields to find only the locations related ones)
  const allFields = cache.inspectFields(entityKey);
  const fieldInfos = allFields.filter((info) => info.fieldName === fieldName);
  const size = fieldInfos.length;

  // If no target fields are found, return undefined
  if (size === 0) {
    return undefined;
  }

  let edges: NullArray<string> = [];
  let pageInfo: PageInfo = defaultPageInfo;
  let typename = '';

  // Iterate over all target fields to aggregate edges and pageInfo
  for (let i = 0; i < size; i++) {
    const { fieldKey, fieldName } = fieldInfos[i];

    const page = getPage(cache, entityKey, fieldKey);
    typename = page?.__typename || typename;

    if (page === null || page.edges.length === 0) {
      continue; // Skip if page is null or has no edges
    }

    edges = [...edges, ...page.edges]; // Concatenate edges
    pageInfo = page.pageInfo; // Update pageInfo

    if (fieldName === 'locations') {
      // Apply additional sorting logic for `location` field
      edges = sortByKindAndName(cache, edges, 'location', predefinedLocationKindsOrder);
    }

    if (fieldName === 'devices') {
      // Apply additional sorting logic for `device` field
      edges = sortByKindAndName(cache, edges, 'device', predefinedDeviceKindsOrder);
    }
  }

  return {
    __typename: typename,
    edges,
    pageInfo: {
      __typename: 'PageInfo',
      ...pageInfo,
    },
  };
};

// Function to retrieve a page of results from the cache
const getPage = (cache: Cache, entityKey: string, fieldKey: string): Page | null => {
  const link = ensureKey(cache.resolve(entityKey, fieldKey));
  if (!link) return null;

  // Resolve the typename and edges of the page
  const typename = cache.resolve(link, '__typename') as string;
  const edges = (cache.resolve(link, 'edges') || []) as NullArray<string>;

  if (typeof typename !== 'string') {
    return null;
  }

  // Construct the initial page object
  const page: Page = {
    __typename: typename,
    edges,
    pageInfo: defaultPageInfo,
  };

  // Attempt to resolve additional pageInfo details
  const pageInfoKey = cache.resolve(link, 'pageInfo');
  if (typeof pageInfoKey === 'string') {
    const endCursor = ensureKey(cache.resolve(pageInfoKey, 'endCursor'));
    const hasNextPage = cache.resolve(pageInfoKey, 'hasNextPage');

    // Update pageInfo with resolved details
    page.pageInfo = {
      hasNextPage: typeof hasNextPage === 'boolean' ? hasNextPage : !!endCursor,
      hasPreviousPage: false,
      endCursor,
      startCursor: '',
    };
  }

  return page;
};

export default onePageCacheResolver;
